import request from '@/utils/request'

// 查询种草筛选项列表
export function listRecommendFilter(query) {
  return request({
    url: '/config/recommend/filter/list',
    method: 'get',
    params: query
  })
}

// 查询种草筛选项详细
export function getRecommendFilter(id) {
  return request({
    url: '/config/recommend/filter/' + id,
    method: 'get'
  })
}

// 新增种草筛选项
export function addRecommendFilter(data) {
  return request({
    url: '/config/recommend/filter',
    method: 'post',
    data: data
  })
}

// 修改种草筛选项
export function updateRecommendFilter(data) {
  return request({
    url: '/config/recommend/filter',
    method: 'put',
    data: data
  })
}

// 删除种草筛选项
export function delRecommendFilter(id) {
  return request({
    url: '/config/recommend/filter/' + id,
    method: 'delete'
  })
}
